import React, { useState } from "react";
import Img from "gatsby-image";
import Fade from "react-reveal/Fade";
import moment from "moment";
import { Link } from "gatsby";
import { set } from "lodash";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Autoplay,Pagination,Navigation
} from 'swiper/core';

import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"

const Hero = ({image}) => {
  const [checked, setChecked] = useState(false);
  SwiperCore.use([Autoplay,Pagination,Navigation]);

  let roastTime = "2021-08-07"; // yy, mm, dd

  let time = moment().to("08-07-2021");

  let today = moment().format("YYYY-MM-DD");

  let isTrue = moment(today).isBefore(roastTime, "day");


  return (
    <div className="hero">
      {/* <Swiper style={{padding: '0'}} pagination={true} autoplay={{'delay': 5000, "disableOnInteraction": false}} >
        {data.map(item => {
          return (
            <SwiperSlide>
              <Img style={{ filter: "grayscale(100%)" }} className="heroImage" alt={item.title} fluid={item.fluid} />
            </SwiperSlide>
          )
        })}
      </Swiper> */}
    <Img style={{ filter: "grayscale(100%)" }} className="heroImage" alt={image.title} fluid={image.fluid} />
     
      <div className="heroWrapper" style={{zIndex: '3'}}>
          <div className="heroDetails heroDetails-rsvp">
            <Fade left delay={500}>
              <div>
                <h1>
                  RSVP for <span>The Roast</span> today!
                   {/* {isTrue ? "is" : "was"} {time} */}
                </h1>
              </div>
            </Fade>
            <Fade right delay={1500}>
              <div className="form">
<p style={{
                       color: 'grey',
                       paddingBottom: '.5rem'
                       }} >
              <span className='theRoast' style={{paddingRight: '3px', fontSize: '17px'}} >The Roast </span> is a formal event, please remember to dress accordingly!
              </p>
                <form name="contact" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
                  <input type="hidden" name="form-name" value="contact" />
                  <div className="form-group">
                    <label className="label">Name</label>
                    <input required placeholder="Richard Jaramillo" className="input" type="text" name="name" />
                  </div>
                  <div className="form-group">
                    <label className="label">Email</label>
                    <input required placeholder="yourname@movement.com" className="input" type="email" name="email" />
                  </div>
                  <div className="form-group-2">
                    <span style={{ fontSize: "20px", fontWeight: "800" }}>Bringing a Guest? </span>
                    <label class="switch">
                      <input
                        type="checkbox"
                        name="guest"
                        value={checked}
                        onChange={() => {
                          setChecked(!checked);
                        }}
                      />
                      <small></small>
                    </label>
                  </div>
                  <div style={checked ? { display: "block" } : { display: "none" }} className="form-group">
                    <label className="label">Guest Name</label>
                    <input placeholder="Don Gidley" className="input" type="text" name="guestName" />
                  </div>
                  <div className="form-group">
                    <label className="label">Choose Menu Item{checked && "s"}</label>
                    <select className="input" name="Menu Item">
                      <option value="nothing selected">- select -</option>
                      <option value="beef">Beef</option>
                      <option value="chicken">Chicken</option>
                      <option value="fish">Fish</option>
                    </select>

                    <select style={checked ? { display: "block" } : { display: "none" }} className="input" name="Menu Item 2">
                      <option value="nothing selected">- select -</option>
                      <option value="beef">Beef</option>
                      <option value="chicken">Chicken</option>
                      <option value="fish">Fish</option>
                    </select>
                    {/* {checked && (
                      <Fade right>
                        <select className="input" name="Menu Item 2">
                          <option value="beef">Beef</option>
                          <option value="chicken">Chicken</option>
                          <option value="fish">Fish</option>
                        </select>
                      </Fade>
                    )} */}
                  </div>
                  {/* <div className="form-group">
                    <p>
                      <span className="required">*</span>By filling out this form you are confirming your attendance for The Roast 2021
                    </p>
                  </div> */}
                  <div style={{ display: "flex", justifyContent: "center", width: "100%", padding: "2rem 0 0" }}>
                    <button className="cta cta-main" style={{ padding: "1.25rem 4rem" }} type="submit">
                      RSVP
                    </button>
                  </div>
                </form>
              </div>
            </Fade>
          </div>
      </div>
    </div>
  );
};
export default Hero;
