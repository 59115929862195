import React from "react";
// import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout/layout";
import Hero from "../components/rsvpHero";
import map from "../assets/map.png";
import moment from "moment";
import Fade from "react-reveal/Fade";

const RSVP = () => {
  const query = useStaticQuery(graphql`
    query RSVPquery {
      contentfulAsset(title: { in: "agent-elevation" }) {
        fluid(maxWidth: 1980, maxHeight: 1280) {
          ...GatsbyContentfulFluid_tracedSVG
        }
        title
      }
    }
  `);

  const hero = query.contentfulAsset;

  let roastTime = "2021-08-07"; // yy, mm, dd

  let time = moment().to("08-07-2021");

  let today = moment().format("YYYY-MM-DD");

  let isTrue = moment(today).isBefore(roastTime, "day");

  return (
    <Layout>
      <section >
        <Hero image={hero} />
      </section>
      <section className="rsvp-container">
        <div className="rsvp-body">
          <Fade>
            <div className="event-details">
              <h2>The Roast</h2>
              <div className="detail-wrapper">
                <div className="headings">
                  <p>Date:</p>
                  <p>Start:</p>
                  <p>End:</p>
                  <p>Address:</p>
                </div>
                <div className="details">
                  <p>August 7, 2021</p>
                  <p>6PM</p>
                  <p>10PM</p>
                  <p>17550 Bernardo Oaks Dr, 92128</p>
                </div>
              </div>
              {/* <p className="event-details-footer">Email your RSVP, You & Guest Name and Menu Item, (Beef / Chicken / Fish) to: Richard.J@Movement.com</p> */}
            </div>
          </Fade>
          <Fade delay={1100}>
            <div className="map-container">
              <div className="event-map">
                <img src={map} alt="map" />
                <a className="map-cover" href="https://www.google.com/maps/place/17550+Bernardo+Oaks+Dr,+San+Diego,+CA+92128/" target="_blank" rel="noreferrer"></a>
              </div>
            </div>
          </Fade>
        </div>
      </section>
    </Layout>
  );
};

export default RSVP;
